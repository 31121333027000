// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-careers-farm-index-jsx": () => import("./../../../src/pages/careers/farm/index.jsx" /* webpackChunkName: "component---src-pages-careers-farm-index-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-careers-restaurant-index-jsx": () => import("./../../../src/pages/careers/restaurant/index.jsx" /* webpackChunkName: "component---src-pages-careers-restaurant-index-jsx" */),
  "component---src-pages-company-index-jsx": () => import("./../../../src/pages/company/index.jsx" /* webpackChunkName: "component---src-pages-company-index-jsx" */),
  "component---src-pages-ie-jsx": () => import("./../../../src/pages/ie.jsx" /* webpackChunkName: "component---src-pages-ie-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-index-jsx": () => import("./../../../src/pages/media/index.jsx" /* webpackChunkName: "component---src-pages-media-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-newsletter-index-jsx": () => import("./../../../src/pages/newsletter/index.jsx" /* webpackChunkName: "component---src-pages-newsletter-index-jsx" */),
  "component---src-pages-openness-index-jsx": () => import("./../../../src/pages/openness/index.jsx" /* webpackChunkName: "component---src-pages-openness-index-jsx" */),
  "component---src-pages-service-index-jsx": () => import("./../../../src/pages/service/index.jsx" /* webpackChunkName: "component---src-pages-service-index-jsx" */),
  "component---src-pages-vision-index-jsx": () => import("./../../../src/pages/vision/index.jsx" /* webpackChunkName: "component---src-pages-vision-index-jsx" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

