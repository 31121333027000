import "./src/styles/scss/index.scss"

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  script.rel = "preload"
  document.body.appendChild(script)
}

export const onRouteUpdate = () => {
  addScript("/asset/js/vendor.bundle.js")
  addScript("/asset/js/app.bundle.js")
}
